import { takeLatest, select, put } from 'redux-saga/effects'

import axios from 'axios';
import { API_URL } from '../constants';
import { setUser, setUsers } from './user'

function* getUser() {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;

        const user = yield axios.get(`${API_URL}/user`, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        })

        if (!user || user.status === 200) {
            yield put(setUser(user.data));
        }

    } catch (e) {

    }
}

export function* getUserSaga() {
    yield takeLatest("GETUSERTRANSACT", getUser);
}

export function getUserAction() {
    return { type: "GETUSERTRANSACT" }
}

function* getUsers() {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken } = userData;

        if (userData.user && userData.user.role === "admin") {

            const user = yield axios.get(`${API_URL}/user/getOperatios`, {
                headers: { Authorization: `Bearer ${jwtToken}` }
            })

            if (!user || user.status === 200) {
                yield put(setUsers(user.data));
                
            }
        }



    } catch (e) {

    }
}

export function* getUsersSaga() {
    yield takeLatest("GETUSERSTRANSACT", getUsers);
}

export function getUsersAction() {
    return { type: "GETUSERSTRANSACT" }
}



