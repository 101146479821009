const translation = {
    translation: {
        "londonbank": "LondonBank",
        "londonbank_description": "We are a sustainable bank that positively transforms the finances of our customers.",
        "lang_es": "Spanish",
        "lang_en": "English",
        "address_location": "100 Bishopsgate Bishopsgate, London, England, EC2N 4AG",
        "address": "Address",
        "email": "Email",
        "email_advice": "advice@banklondongroup.uk",
        "email_support": "legal@banklondongroup.uk",
        "phone_line": "442037690927",
        "search_placeholder": "Search...",
        "virtual branch people": "Virtual Branch for Individuals",
        "go to virtual branch people": "Go to the virtual branch",
        "services": "Services",
        "i need an account": "I need an account",
        "i want to invest": "I want to invest",
        "i want an insurance": "I want insurance",
        "I want to make an international wire transfer": "International transfers",
        "I need advice": "I need advice",
        "information": "Information",
        "contact": "Contact us",
        "about us": "About us",
        "view more": "See more",
        "chief executive officer name": "Sir Jon Cunliffe",
        "chief executive officer": "Chief Executive Officer",
        "hire": "Hire",
        "see eligibility and limitations": "See terms and conditions",
        "financial consumer services": "Consumer financial services",
        "financial consumer services small description": "Discover all the sustainable solutions we have for you.",
        "digital channels": "Digital channels",
        "digital channels small description": "Save time with our digital channels",
        "open an account": "Open account",
        "visit us": "Visit us",
        "visit us large": "Visit us at any of our branches",
        "visit us small description": "Our advisors are available to assist you",
        "blog": "Financial Health Blog",
        "blog small description": "Improve your financial health and enjoy the peace of mind that comes with it.",
        "previous": "Previous",
        "next": "Next",
        "callToAction-1": "Join the LondonBank family now",
        "callToAction-1 description": "Open your free account in just a few minutes.",
        "useful links": "Useful information",
        "financial health": "Financial Health",
        "twitter": "X",
        "news londonbank": "LondonBank News",
        "subscribe": "Subscribe",
        "subscribe calltoaction": "Subscribe now!",
        "send": "Send",
        "opening hours": "Opening hours",
        "schedule your advice": "Schedule your consultation",
        "schedule": "Schedule",
        "do you need financial advice?": "Do you need financial advice?",
        "do you need financial advice? calltoaction": "Don't wait any longer to get free advice. Our advisors are ready to support you.",
        "account types": "LondonBank Accounts",
        "apply here": "Apply here",
        "not available for this region": "Not available in this region",
        "Investment funds": "Investment Funds",
        "international wire transfer": "International Transfers",
        "full name": "Full name",
        "phone": "Phone",
        "subject": "Subject",
        "message": "Message",
        "conctat title": "We want to provide you with the best advice.",
        "conctat small description": "We understand that every person is unique and that's why we want to provide you with the best personalized advice to achieve your goals.",
        "success form title subscribe": "Thank you for joining the LondonBank family!",
        "error form description subscribe": "But it seems there has been a problem sending your application. Please check that your email is correct and try again.",
        "success form title": "Thank you for contacting us!",
        "success form description": "We have received your message and will get back to you as soon as possible.",
        "error form title": "Sorry",
        "error form description": "But it seems there has been a problem sending your contact form. Please check that all required fields are complete and try again.",
        "enter information call to action": "We need to validate your identity. Please enter the following information.",
        "enter basic information": "Enter your basic information",
        "basic information": "Basic information",
        "name": "Name",
        "first name": "First name",
        "last name": "Last name",
        "i agree to the": "I accept the",
        "terms & conditions": "terms & conditions",
        "and": "and",
        "privacy policy": "privacy policy.",
        "personal info": "Personal information",
        "enter personal information": "Enter your personal information",
        "country": "Country",
        "state": "Region",
        "city": "City",
        "account detail": "Account details",
        "account type": "Account type",
        "verify identity": "Identity verification",
        "date of birth": "Date of birth",
        "dni": "ID number",
        "dni small description": "Enter your ID number if you are an individual or your NIF if you are a company or a limited liability company with a single owner.",
        "birthplace": "Place of birth",

        "suscription check": "I agree to receive information from LondonBank electronically at the email address provided.",
        "this is a required field": "This field is required",
        "must be a valid email": "Invalid email",
        "must be a valid number": "Invalid number",
        "must be a valid date": "Invalid date",
        "select an option": "Select an option",
        "terms and conditions validation": "You must accept LondonBank's policies to submit the product request",
        "zip": "Zip",
        "request": "Request",

        "request success message": "Thank you for joining the [londonbank] family, our advisors are reviewing your application and will be in touch with you as soon as possible. To follow up on your request, you can send us an email to [support_email] indicating the registration number #[registered_id] or by calling [phone_number], we are attentive to any concerns.",

        "registration number": "Registration number",
        "user": "User",
        "pass": "Password",
        "pass repeat": "Password repeat",
        "login": "Login",
        "length not allowed": "Length not allowed",
        "error login": "Incorrect user or password",
        "error login block": "You have exceeded the maximum number of permitted attempts. For security reasons, your account has been temporarily locked. Please try again in 1 hour. Should you require assistance, contact our support team.",
        "view balance": "View balance",
        "hi": "Hello",
        "products": "Products",
        "balance": "Available balance",
        "last payment": "Last transaction",
        "balance call to action": "If you do not have any subscribed products yet, please contact our advisors at the [phone_number] line or visit",
        "average profit": "Average yield",
        "accrued profit": "Accumulated yield",
        "transactions": "Movements",
        "recent activity": "Recent activity",
        "payment profitability": "Pay earnings",
        "initdeposit": "Deposit",
        "credit": "Credit",
        "deposit": "Deposit",
        "deposits": "Deposits",
        "View all activity": "Load more movements",
        "View all products": "Load more products",
        "logout": "Log out",
        "account number": "Account number",
        "account": "Account",
        "accounts": "Accounts",
        "active": "Assets",
        "review": "Under review",
        "aprove": "Approve",
        "refuse": "Reject",
        "cancel": "Cancel",
        "receipt number": "Proof number",
        "registration number title": "Registration number",
        "detail": "Detail",
        "active account": "Activate account",
        "active account small description": "The user requires activating the account to continue.",
        "date": "Date",
        "amount": "Amount",
        "product": "Product",
        "status": "Status",
        "account status": "Account status",
        "account-active": "Active",
        "account-review": "Under review",
        "deposit-active": "Active",
        "deposit-review": "Under review",
        "initial amount": "Initial amount",
        "request amount": "Requested amount",
        "operation number": "Operation number",
        "add deposit": "Create deposit",
        "add deposit to new user": "Create deposit to a new user",
        "create account": "Create account",
        "unable content": "Content not available",
        "remove deposit": "Delete deposit",
        "close": "Close",
        "remove account": "Delete account",
        "unactive account": "Deactivate account",
        "select an status": "Select a status",
        "select an user": "Select an account",
        "transfers": "Transfers",
        "register products": "Register accounts",
        "registered products": "Registered accounts",
        "nikname": "Alias",
        "recipient name": "Beneficiary name",
        "type code": "Code type",
        "code": "Code",
        "recipient bank": "Beneficiary bank",
        "Value to be transferred": "Value to transfer",
        "transfer money": "Transfer money",
        "transfer money small description": "If you don't see one of your products, it may be hidden.",
        "unable product": "Product not available.",
        "insufficient funds": "Insufficient funds.",
        "pending": "Pending",
        "credit londonbank": "LondonBank Credit",
        "product detail": "Product detail",
        "credit request": "Apply for credit",
        "privacy policy title": "Privacy policy",
        "the general data protection regulation": "The General Data Protection Regulation",
        "cookie policy": "Cookie policy",
        "exceeded value": "Value exceeds the minimum allowed of ",
        "forget password": "Forget password",
        "forgot password title": "Forgot password?",
        "forgot password small description": "Enter the email address associated with your account and we will send you a link to reset your password.",
        "password validation": "Your password must have 6 digits.",
        "password validation repeat": "Password does not match",
        "deposit type": "Deposit type",
        "withdrawal": "Withdrawal",

    }
}

export default translation;